import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Alert } from "reactstrap"
import styled from "styled-components"
import Select from "react-select"
import { Link } from "gatsby"

const tmjbooks = [
  {
    value: "https://amzn.to/3MBCBRb",
    label:
      "The Beginner's Guide to Raising Chickens: How to Raise a Happy Backyard Flock -- Anne Kuo",
  },
  {
    value: "https://amzn.to/3vnmZLt",
    label: "Storey's Guide to Raising Chickens, 4th Edition: Breed Selection, Facilities, Feeding, Health Care, Managing Layers & Meat Birds -- Gail Damerow",
  },
  {
    value: "https://amzn.to/3OJPRVV",
    label: "The Homesteader's Natural Chicken Keeping Handbook: Raising a Healthy Flock from Start to Finish -- Amy K. Fewell",
  },
  {
    value: "https://amzn.to/3rYBNOp",
    label: "The Chicken Chick's Guide to Backyard Chickens: Simple Steps for Healthy, Happy Hens -- Kathy Shea Mormino",
  },
]

class TMJBooks extends Component {
  constructor(props) {
    super(props)
    this.state = { selectedOption: null }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(selectedOption) {
    this.setState({ selectedOption })
    window.open(selectedOption.value, `_blank`, `noopener`)
    console.log(`Option selected:`, selectedOption)
  }

  render() {
    const { selectedOption } = this.state

    return (
      <Select
        placeholder="Select a book..."
        onChange={this.handleChange}
        value={selectedOption}
        options={tmjbooks}
        isOptionDisabled={(option) => option.disabled === "yes"}
      />
    )
  }
}

function BuyButton(props) {
  return (
    <div style={{ width: `100%`, textAlign: `center` }}>
      <BuyNow href={props.url} target="_blank" rel="noopener noreferrer">
        {props.text}
      </BuyNow>
    </div>
  )
}

const AboutPage = () => (
  <Layout>
    <SEO
      title="Best Tools And Resources For Raising Chickens"
      description="Here you'll find my top resources and tools for raising chickens that I've hand selected and sampled."
      image="https://chicken-yard.net/og-image.jpg"
      url="https://chicken-yard.net/resources"
    />
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col className="p-0">
          <PurpleSection>
            <AboutContent>
              <h1 className="text-center" style={{ fontFamily: `Jost` }}>
                The Best Resources And
                Tools For Raising Chickens
              </h1>
            </AboutContent>
          </PurpleSection>
        </Col>
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <AboutContent>
            <p>
              <b>
                Are you looking for the best resources and tools for keeping poultry? 🐔
              </b>
            </p>
            <p>
              On this page you'll find my list of favorite and most recommended products
              for keeping chickens. There are so many great
              tools and products out there to choose from but I've
              personally selected a few based on my experience.
            </p>
            <Disclaimer>
              <b>
                <u>Full transparency</u>:
              </b>{" "}
              You should always assume that any links I provide are affiliate links.
              First and foremost I want to give you free and useful content, but my blog is a for-profit business. This means that if I can earn a commission by
              referring you to something, I probably will.
            </Disclaimer>
            <ShortLine />
            <SubHeading>Chicken Coop Construction</SubHeading>
            <p>
              <b>Easy Coops:</b> This is a phenomenal resource that provides 15 high quality construction guides for all kinds of chicken coop. It's pretty much everything you need in one bundle, and costs just under $30. Anyone serious about building their own coop should get a copy of this. 
            </p>
            <BuyButton
              text="See Easy Coops"
              url="/goto/easycoops"
            />
            <p>
              <b>Building A Chicken Coop:</b> This is the highly popular and established <em>Building A Chicken Coop</em> program. This program has helped thousands of people build high quality chicken coops at home at minimal cost. Highly recommended.
            </p>
            <BuyButton
              text="See Building A Chicken Coop"
              url="/goto/buildingachickencoop"
            />
            
            <SubHeading>
              Recommended reading on raising and breeding chickens
            </SubHeading>
            <p>
              Here are some quick links to my
              favorite books (on Amazon):
            </p>
            <TMJBooks />
            
            <SubHeading>
              Chicken fencing
            </SubHeading>
            <p>
              <b>MTB Galvanized Chicken Wire:</b> You're not going to get far without quality netting. MTB is my favorite and super affordable for a roll.
            </p>
            <p>Hint: Choose 150 feet as you'll need to make repairs eventually and it's good to have on hand.</p>
            <BuyButton
              text="See MTB Chicken Wire"
              url="https://amzn.to/3KhDvkg"
            />
            <SubHeading>
              Ready-made coop
            </SubHeading>
            <p>
              <b>PawHut Galvanized Chicken Coop:</b> Don't have time or energy to build yourself a chicken coop and just want something that is ready to go (and will last forever)?
            </p>
            <p>What you probably want is a galvanized chicken coop. It's very simple in design (doesn't win design awards) but will stay up in a hurricane.</p>
            <BuyButton
              text="See PawHut Chicken Coop"
              url="https://amzn.to/3ESO7F4"
            />
            <ShortLine />
            <p>That's it.</p>
            <p>
              If there's anything I absolutely should add to this list, please{" "}
              <Link to="/contact/">contact me</Link> and let me know.
            </p>
          </AboutContent>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage

const ShortLine = styled.hr`
  width: 75%;
`
const HeroImage = styled.div`
  position: relative;
`
const AboutContent = styled.div`
  max-width: 800px;
  margin: auto;
  padding-bottom: ;
`
const ColorSection = styled.div`
  background-image: linear-gradient(160deg, #d25337 0%, #181313 95%);
  color: #fff;
  padding-top: 5px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  p {
    color: #fff !important;
  }
  a {
    color: #5b9af5;
    &:hover {
      color: #34578b !important;
    }
  }
`
const PurpleSection = styled.div`
  //background-image: linear-gradient(316deg, #1e365c 0%, #131313 84%);
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  h1 {
    -webkit-text-fill-color: transparent;
  background: linear-gradient(#867350,#544b38);
  -webkit-background-clip: text;
  color: #fff;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1.25;
  }
  p {
    color: #fff !important;
  }
  a {
    color: #f56545;
    &:hover {
      color: #d25337 !important;
    }
  }
`
const SubHeading = styled.h2`
  margin-top: 1em;
  color: #e39f1e;
  text-align: center;
`
const Disclaimer = styled(Alert)`
  border: none !important;
  color: #fff !important;
  background-color: #e39f1e !important;
  font-style: italic;
`
const BuyNow = styled.a`
  font-size: 100%;
  font-weight: 700;
  cursor: pointer;
  display: block;
  max-width: 300px;
  margin-top: 15px;
  margin-right: auto;
  margin-bottom: 15px;
  margin-left: auto;
  padding: 10px 5px 10px 5px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-transform: uppercase;
  color: #fff !important;
  border-radius: 6px;
  background: #000;
  background-image: linear-gradient(to right, #161616, #e39f1e);
  box-shadow: 0 1px 3px #000;
  &:hover {
    color: #fff !important;
    box-shadow: none;
  }
`
